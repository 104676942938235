body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --color-brand: #d53a2c;
  --color-highlight: #e2e1e0;
}

input,
button {
  font-family: inherit;
}

input[type="text"] {
  box-sizing: border-box;
  padding: 0.7rem 2rem;
  margin: 0;
  border: none;
  font-size: 1.2rem;
}

input[type="file"] {
  box-sizing: border-box;
  padding: 0.7rem 2rem;
  margin: 0;
  border: none;
  font-size: 1.2rem;
}

input[type="email"] {
  width: 273px;
  box-sizing: border-box;
  padding: 0.7rem 2rem;
  margin: 0;
  border: none;
  font-size: 1.2rem;
}

input[type="number"] {
  width: 273px;
  box-sizing: border-box;
  padding: 0.7rem 2rem;
  margin: 0;
  border: none;
  font-size: 1.2rem;
}

select {
  -webkit-appearance: initial;
  width: 273px;
  box-sizing: border-box;
  padding: 0.7rem 2rem;
  margin: 0;
  border: none;
  font-size: 1.2rem;
}

label {
  margin: 0.5rem 1rem;
  border: solid 2px black;
  position: relative;
}

label > span {
  position: absolute;
  top: 0;
  left: 1rem;
  transform: translateY(-50%);
  background: white;
  padding: 0.1rem 0.3rem;
}

.react-datepicker__input-container{
  padding: 0.7rem 2rem;
  width: auto;
}

.react-datepicker__input-container > input[type="text"] {
  padding: 0!important  ;
}

.timezone{
  padding: 0.7rem 2rem;
  width: 200px;
}

.react-tel-input .form-control {
  border-radius: 0!important;
}

.rmsc {
  width: 225px!important;
  }