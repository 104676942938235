.btn {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  margin: 0.5rem;
  background: white;
  color: var(--color-brand);
  border: solid 3px var(--color-brand);
  transition: all ease-in 0.2s;
}

.btn:hover {
  background: var(--color-brand);
  color: white;
}
