.container {
  width: 100%;
  height: 100vh;
  display: flex;
}

.branding,
.content {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 60%;
}

.content {
  background: var(--color-highlight);
}

.form {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 3rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.formTitle {
  font-size: 2rem;
  box-sizing: border-box;
  margin: 2rem 1rem;
}

.select {
  width: -webkit-fill-available!important;
  padding: 0.7rem 2rem!important;
}

a {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  margin: 0.5rem;
  background: white;
  color: var(--color-brand);
  border: solid 3px var(--color-brand);
  transition: all ease-in 0.2s;
  text-decoration: none;
}

a:hover {
  background: var(--color-brand);
  color: white;
}